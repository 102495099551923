import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { catchError, tap } from 'rxjs/operators';
import { Herb } from '../model/herb';
import { environment } from 'src/environments/environment.prod';

const baseUrl = environment.baseURL + '/api/herbs';

@Injectable({
  providedIn: 'root'
})
export class HerbsListService {



  constructor(private http: HttpClient) { 

  }

  getAllHerbs(): Observable<Herb[]>{

    return this.http.get<Herb[]>(baseUrl+'/list')
      .pipe(
        tap( _ => console.log('herbs list found') ),
        catchError( err => of([]) ) 
        
      );

  } 

  
  deleteHerbItem(herbID: String): Observable<Number>{

    return this.http.delete<any>(baseUrl + '/deleteHerbItem?herbID=' + herbID)
      .pipe(
        tap( _ => console.log('herb item delete value')),
        catchError( err => of([]))
      )

   }


}
