import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Interaction } from '../model/interaction';
import { environment } from '../../../environments/environment.prod';

const baseUrl = environment.baseURL + '/api/interactions';

@Injectable({
  providedIn: 'root'
})
export class InteractionService {

  constructor(private http: HttpClient) { 

  }

  saveInteraction(interaction: Interaction): Observable<Number>{

    return this.http.post<any>(baseUrl + '/saveInteraction', interaction)
      .pipe(
        tap( _ => console.log('interaction added value') ),
        catchError( err => of ([]))
      );

  }


  updateInteraction(interaction: Interaction): Observable<Number>{

    return this.http.put<any>(baseUrl + '/updateInteraction', interaction)
      .pipe(
        tap( _ => console.log('interaction update value') ),
        catchError( err => of ([]) )
      );
    
  }

  getInteractionItem(interactionID: String): Observable<Interaction>{

    return this.http.get<any>(baseUrl + '/getInteractionItem?interactionID=' +interactionID)
    .pipe(
      tap( _ => console.log('interaction item get value') ),
      catchError( err => of ([]) )
    );

  }

  getAllInteraction(){

    return this.http.get<Interaction[]>(baseUrl + '/list')
      .pipe(
        tap ( _ => console.log('interactions list found') ),
        catchError( err => of ([]) )
      );
  }

  deleteInteractionItem(interactionID: String): Observable<Number>{

    return this.http.delete<any>(baseUrl + '/deleteInteractionItem?interactionID=' + interactionID)
    .pipe(
      tap ( _ => console.log('interaction item delete value') ),
      catchError( err => of ([]) )
    );

  }

  getHerbPrepDrugGeneralInteractionList(): Observable<any>{

    return this.http.get<any>(baseUrl + '/getHerbPrepDrugGeneralInteractionList')
    .pipe(
        tap( _ => console.log('Herb Prep, Drug and General Interactions') ),
        catchError( err => ([]) )
    );

  }



}
