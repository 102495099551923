import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HerbPreparation } from '../model/herbpreparation';
import { environment } from '../../../environments/environment.prod';

const baseUrl = environment.baseURL + '/api/herbprep';

@Injectable({
  providedIn: 'root'
})
export class HerbPreparationService {

  constructor(private http: HttpClient) { 

  }

saveHerbPrep(herbPrep: HerbPreparation): Observable<Number>{

  return this.http.post<any>(baseUrl + '/saveHerbPrep', herbPrep )
    .pipe(
      tap(  _ => console.log('herbPrep added value') ),
      catchError( err => of ([]))
    );

}

updateHerbPrep(herbPrep: HerbPreparation): Observable<Number>{

  return this.http.put<any>(baseUrl + '/updateHerbPrep', herbPrep)
    .pipe(
      tap( _ => console.log('herbPrep update value') ),
      catchError( err => of ([]) )
    );
  
}

getHerbPrepItem(herbPrepID: String): Observable<HerbPreparation>{

  return this.http.get<any>(baseUrl + '/getHerbPrepItem?herbPrepID=' + herbPrepID)
    .pipe(
      tap( _ => console.log('herbPrep item get value') ),
      catchError( err => of([]) )
    );

}

getAllHerbPreps(): Observable<HerbPreparation[]>{

  return this.http.get<HerbPreparation[]>(baseUrl + '/list')
    .pipe(
      tap ( _ => console.log('herbPreps list found') ),
      catchError( err => of ([]) )
    );

}

deleteHerbPrepItem(herbPrepID: String): Observable<Number>{

  return this.http.delete<any>(baseUrl + '/deleteHerbPrepItem?herbPrepID=' + herbPrepID)
  .pipe(
    tap( _ => console.log('herbPrep item delete value') ),
    catchError ( err => of([]) )
  );

}




}
