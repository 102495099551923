import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Drug } from '../model/drug';
import { tap, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { environment } from '../../../environments/environment.prod';

const baseUrl = environment.baseURL + '/api/drugs';

@Injectable({
  providedIn: 'root'
})
export class DrugService {

  constructor(private http: HttpClient) { 

  }

  saveDrug(drug: Drug): Observable<Number>{

    return this.http.post<any>(baseUrl + '/saveDrug', drug)
      .pipe(
        tap( _ => console.log('drug added value') ),
        catchError( err => of ([]))
      );

  }


  updateDrug(drug: Drug): Observable<Number>{

    return this.http.put<any>(baseUrl + '/updateDrug', drug)
      .pipe(
        tap( _ => console.log('drug update value') ),
        catchError( err => of ([]) )
      );
    
  }

  getDrugItem(drugID: String): Observable<Drug>{

    return this.http.get<any>(baseUrl + '/getDrugItem?drugID=' + drugID)
    .pipe(
      tap( _ => console.log('drug item get value') ),
      catchError( err => of ([]) )
    );

  }

  getAllDrugs(){

    return this.http.get<Drug[]>(baseUrl + '/list')
      .pipe(
        tap ( _ => console.log('drugs list found') ),
        catchError( err => of ([]) )
      );
  }

  deleteDrugItem(drugID: String): Observable<Number>{

    return this.http.delete<any>(baseUrl + '/deleteDrugItem?drugID=' + drugID)
    .pipe(
      tap ( _ => console.log('drug item delete value') ),
      catchError( err => of ([]) )
    );

  }


  getTciAndDrugClasses(): Observable<any>{

    return this.http.get<any>(baseUrl + '/getTciAndDrugClasses')
      .pipe(
        tap( _ => console.log('Tci and Drug Classes') ),
        catchError( err => of ([]) )
      );

  }




}
