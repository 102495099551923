import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Report } from '../model/report';
import { environment } from '../../../environments/environment.prod';

const baseUrl = environment.baseURL + '/api/reports';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) { 

  }

  saveReport(report: Report): Observable<Number>{

    return this.http.post<any>(baseUrl + '/saveReport', report)
      .pipe(
        tap( _ => console.log('report added value') ),
        catchError( err => of ([]))
      );

  }


  updateReport(report: Report): Observable<Number>{

    return this.http.put<any>(baseUrl + '/updateReport', report)
      .pipe(
        tap( _ => console.log('report update value') ),
        catchError( err => of ([]) )
      );
    
  }

  getReportItem(reportID: String): Observable<Report>{

    return this.http.get<any>(baseUrl + '/getReportItem?reportID=' +reportID)
    .pipe(
      tap( _ => console.log('report item get value') ),
      catchError( err => of ([]) )
    );

  }

  getAllReport(){

    return this.http.get<Report[]>(baseUrl + '/list')
      .pipe(
        tap ( _ => console.log('reports list found') ),
        catchError( err => of ([]) )
      );
  }

  deleteReportItem(reportID: String): Observable<Number>{

    return this.http.delete<any>(baseUrl + '/deleteReportItem?reportID=' + reportID)
    .pipe(
      tap ( _ => console.log('report item delete value') ),
      catchError( err => of ([]) )
    );

  }

  getInteractionsAndReferences(): Observable<any>{

    return this.http.get<any>(baseUrl + '/getInteractionsAndReferences')
    .pipe(
        tap( _ => console.log('Interactions and References') ),
        catchError( err => ([]) )
    );

  }



}
