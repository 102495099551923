import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Reference } from '../model/reference';
import { environment } from '../../../environments/environment.prod';

const baseUrl = environment.baseURL + '/api/references';

@Injectable({
  providedIn: 'root'
})
export class ReferenceService {

  constructor(private http: HttpClient) { 

  }

  saveReference(reference: Reference): Observable<Number>{

    return this.http.post<any>(baseUrl + '/saveReference', reference)
      .pipe(
        tap( _ => console.log('reference added value') ),
        catchError( err => of ([]))
      );

  }


  updateReference(reference: Reference): Observable<Number>{

    return this.http.put<any>(baseUrl + '/updateReference', reference)
      .pipe(
        tap( _ => console.log('reference update value') ),
        catchError( err => of ([]) )
      );
    
  }

  getReferenceItem(referenceID: String): Observable<Reference>{

    return this.http.get<any>(baseUrl + '/getReferenceItem?referenceID=' + referenceID)
    .pipe(
      tap( _ => console.log('reference item get value') ),
      catchError( err => of ([]) )
    );

  }

  getAllReferences(){

    return this.http.get<Reference[]>(baseUrl + '/list')
      .pipe(
        tap ( _ => console.log('references list found') ),
        catchError( err => of ([]) )
      );
  }

  deleteReferenceItem(referenceID: String): Observable<Number>{

    return this.http.delete<any>(baseUrl + '/deleteReferenceItem?referenceID=' + referenceID)
    .pipe(
      tap ( _ => console.log('reference item delete value') ),
      catchError( err => of ([]) )
    );

  }


}
