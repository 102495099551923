import { Injectable } from '@angular/core';

import { Tci } from '../model/tci';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';

const baseUrl = environment.baseURL + '/api/tcis';

@Injectable({
  providedIn: 'root'
})
export class TciService {

  constructor(private http: HttpClient) { 

  }

  saveTci(tci: Tci): Observable<Number>{

    return this.http.post<any>(baseUrl + '/saveTci', tci)
      .pipe(
        tap( _ => console.log('tci added value') ),
        catchError( err => of([]) )
      );

  }

  updateTci(tci : Tci): Observable<Number>{
    
    return this.http.put<any>(baseUrl + '/updateTci', tci)
    .pipe(
      tap( _ => console.log('tci update value') ),
      catchError( err => of([]) )
    );

  }

  getTciItem(tciID: String): Observable<Tci>{

    return this.http.get<any>(baseUrl + '/getTciItem?tciID=' + tciID)
      .pipe(
        tap( _ => console.log('tci item get value') ),
        catchError( err => of([]) )
      )

  }

  getAllTcis(): Observable<Tci[]>{

    return this.http.get<Tci[]>(baseUrl + '/list')
      .pipe(
        tap( _ => console.log('tcis list found') ),
        catchError( err => of([]) )

      );

  }

  deleteTciItem(tciID: String): Observable<Number>{

    return this.http.delete<any>(baseUrl + '/deleteTciItem?tciID=' + tciID)
      .pipe(
        tap( _ => console.log('tci item delete value') ),
        catchError( err => of([]) )

      );

  }



}
