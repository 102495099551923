import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { GeneralInteraction } from '../model/generalInteraction';
import { environment } from '../../../environments/environment.prod';

const baseUrl = environment.baseURL + '/api/generalInteractions';

@Injectable({
  providedIn: 'root'
})
export class GeneralInteractionService {

  constructor(private http: HttpClient) { 

  }

  saveGenInteraction(genInteraction: GeneralInteraction): Observable<Number>{

    return this.http.post<any>(baseUrl + '/saveGenInteraction', genInteraction)
      .pipe(
        tap( _ => console.log('genInteraction added value') ),
        catchError( err => of ([]))
      );

  }


  updateGenInteraction(genInteraction: GeneralInteraction): Observable<Number>{

    return this.http.put<any>(baseUrl + '/updateGenInteraction', genInteraction)
      .pipe(
        tap( _ => console.log('genInteraction update value') ),
        catchError( err => of ([]) )
      );
    
  }

  getGenInteractionItem(interactionID: String): Observable<GeneralInteraction>{

    return this.http.get<any>(baseUrl + '/getGenInteractionItem?genInteractionID=' +interactionID)
    .pipe(
      tap( _ => console.log('genInteraction item get value') ),
      catchError( err => of ([]) )
    );

  }

  getAllGenInteraction(){

    return this.http.get<GeneralInteraction[]>(baseUrl + '/list')
      .pipe(
        tap ( _ => console.log('genInteractions list found') ),
        catchError( err => of ([]) )
      );
  }

  deleteGenInteractionItem(genInteractionID: String): Observable<Number>{

    return this.http.delete<any>(baseUrl + '/deleteGenInteractionItem?genInteractionID=' + genInteractionID)
    .pipe(
      tap ( _ => console.log('genInteraction item delete value') ),
      catchError( err => of ([]) )
    );

  }

  getHerbTciAndDrug(): Observable<any>{

    return this.http.get<any>(baseUrl + '/getHerbTciAndDrug')
    .pipe(
        tap( _ => console.log('Herbs, Tcis and Drugs') ),
        catchError( err => ([]) )
    );

  }



}
